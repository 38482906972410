// extracted by mini-css-extract-plugin
export var title1 = "typography-module--title1--ykPsQ";
export var title2 = "typography-module--title2--rRE0W";
export var title3 = "typography-module--title3--1IGom";
export var large = "typography-module--large--qtBJk";
export var base = "typography-module--base--G3rP+";
export var small = "typography-module--small--sga0O";
export var micro = "typography-module--micro--FEkzu";
export var paragraph = "typography-module--paragraph--KEmyy";
export var blockQuote = "typography-module--blockQuote--vb8Qw";
export var responsiveTitle1 = "typography-module--responsiveTitle1--57vqX";
export var responsiveTitle2 = "typography-module--responsiveTitle2--FAdM-";
export var responsiveTitle3 = "typography-module--responsiveTitle3--a1ULO";
export var responsiveTitle4 = "typography-module--responsiveTitle4--5qmqp";